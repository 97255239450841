<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-form-group class="col-md-12" label="Phone Number:" label-for="english_title">
                    <ValidationProvider name="phone" ref="phone" rules="digits:11" v-slot="{ errors }">
                      <b-form-input :disabled="disabled == 1" @update="searchPhoneNumber" v-model="lead.phone"
                                    type="number" placeholder="Phone Number"
                                    :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <div class="new-user-info" v-if="finishSearch&&!currentUser">
                  <b-row>
                    <b-form-group class="col-md-12" label="Name:" label-for="name">
                      <ValidationProvider name="name" ref="name" :rules="{
                        regex: /^[a-zA-Z\s]*$/,
                        required:true
                      }" v-slot="{ errors }">
                        <b-form-input v-model="lead.name" type="text" placeholder="Lead Name"
                                      :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-12" label="Project:" label-for="project">
                      <ValidationProvider name="project" ref="project" rules="required" v-slot="{ errors }">
                        <v-select id="enterprise" multiple v-model="selectedProject" :options="allFeatures.projects"
                                  label="en_title" required
                                  :selectable="option => !selectedProject.includes(option.id)"
                                  :reduce="option => option.id"
                                  @option:deselected="removeProject" @option:selecting="addProject"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-12" label="Selected Projects:" label-for="project"
                                  v-if="lead.projects.length > 0">
                      <div class="projectsPrice">
                        <div class="content-price mb-1" v-for="(project, parentKey) in lead.projects" :key="parentKey">
                          <p class="text-primary px-2 font-size-18">{{ project.en_title }} *</p>
                          <b-form-group class="col-md-12" label="Inquiry:" label-for="inquiry">
                            <select class="form-control bg-white" id="inquiry" required
                                    v-model="lead.projects[parentKey].inquiry">
                              <option selected disabled value="">Choose...</option>
                              <option v-for="(inquiry , key) in allFeatures.inquiry" :key="key" :value="inquiry.id">
                                {{ inquiry.title }}
                              </option>
                            </select>
                          </b-form-group>
                          <b-form-group class="col-md-12" label="Comment:" label-for="comment">
                            <b-textarea class="bg-white" v-model="lead.projects[parentKey].note"></b-textarea>
                          </b-form-group>
                          <b-row class="d-flex flex-wrap col-md-12">
                            <b-col v-for="(status, childKey) in allStatus" :key="childKey"
                                   class="col-4 text-black text-center mb-3 cursor-pointer">
                                <span
                                    :class="['d-inline-block w-100 p-1 custom-rounded  primary-color bg-white',{
                                      'shadow bg-primary text-white' : isSelected(parentKey,status.id)}]"
                                    @click="lead.projects[parentKey].status = status.id">
                                <i :class="[{ 'las la-check-square' : isSelected(parentKey,status.id)}]"></i> {{status.title }}
                                </span>
                                <span class="d-block w-100 h-10 bg-white"></span>
                                <span :style="[{ backgroundColor:status.color }]" class="d-block w-100 h-10"></span>
                            </b-col>
                          </b-row>
                          <hr class="bg-primary" v-show="lead.projects.length-1 > parentKey"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-6" label="Source:" label-for="source">
                      <select class="form-control" id="source" required v-model="lead.source">
                        <option selected disabled value="">Choose...</option>
                        <option v-for="(source , key) in allFeatures.source" :key="key" :value="source.id">
                          {{ source.title }}
                        </option>
                      </select>
                    </b-form-group>
                    <b-form-group class="col-md-6" label="From:" label-for="from">
                      <select class="form-control" id="from" required v-model="lead.from">
                        <option selected disabled value="">Choose...</option>
                        <option v-for="(from , key) in allFeatures.from" :key="key" :value="from.id">{{ from.title }}
                        </option>
                      </select>
                    </b-form-group>
<!--                    <b-form-group label="Comment" label-for="Comment" class="col-md-12">
                      <b-form-textarea id="Comment" rows="2" v-model="lead.note"></b-form-textarea>
                    </b-form-group>-->
                  </b-row>
                  <div class="d-flex  mt-4 align-items-center justify-content-end">
                    <b-button v-if='loadingAddLead' class="btn btn-primary float-right" variant="primary" disabled>
                      <spinner-loading text="Loading"/>
                    </b-button>
                    <b-button variant="primary" v-else type="submit">Add My Lead</b-button>
                  </div>
                </div>
                <div class="user-branch" v-if="finishSearch&&currentUser">
                  <b-list-group>
                    <b-list-group-item v-for="(data, key) in listData" :key="key" href="#"
                                       :class="{ 'active' : isSelected(key) }" v-on:click="selected = key"
                                       class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ data.data.name }}</h5>
                        <small class="text-muted">{{ data.data.status }}</small>
                      </div>
                      <p class="mb-1">{{ lead.phone }}</p>
                      <small class="text-muted">{{ data.branch }}</small>
                    </b-list-group-item>
                  </b-list-group>
                  <div class="d-flex justify-content-end mt-3">
                    <b-button variant="primary" class="mr-2" type="submit" @click.prevent="showDetails()"
                              v-if="typeof (selected) == 'number'">Show Details
                    </b-button>
                  </div>
                </div>
                <div v-if="loadingDataFromPhone" class="text-center">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../../config/pluginInit'
import leadService from '../services/leads'

export default {
  name: 'addLead',
  props: {
    edit: {
      default: false,
      type: Boolean
    },
    allStatus: {
      type: Array
    },
    allFeatures: {
      type: Object
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loadingAddLead: false,
      selectedStatusId: '',
      lead: {
        phone: '',
        name: '',
        source: '',
        projects: [],
        from: ''
      },
      selectedProject: [],
      lead_id: '',
      listData: [],
      finishSearch: false,
      currentUser: false,
      disabled: 0,
      selected: '',
      errors: [],
      loadingDataFromPhone: false
    }
  },
  methods: {
    isSelected (projectIndex, id) {
      return id === this.lead.projects[projectIndex].status
    },
    searchPhoneNumber () {
      this.lead.name = ''
      this.finishSearch = false
      this.listData = []
      if (this.lead.phone.length === 11) {
        this.loadingDataFromPhone = true
        this.disabled = 1
        leadService.getDataByPhoneNumber({ phone: this.lead.phone }).then((res) => {
          if (res.data.data.id) {
            this.lead_id = res.data.data.id
            this.lead.name = res.data.data.name
            this.currentUser = false
            this.finishSearch = true
            this.loadingDataFromPhone = false
            this.disabled = 0
          } else {
            this.currentUser = false
            for (const [key, value] of Object.entries(res.data.data)) {
              this.finishSearch = true
              if (typeof (value) === 'object') {
                if (value.data != null) {
                  this.listData.push({ branch: key, ...value })
                  this.currentUser = true
                }
              }
            }
            this.disabled = 0
            this.loadingDataFromPhone = false
          }
        }).catch(err => {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            this.$refs[key].setErrors(value)
            this.loadingDataFromPhone = false
            this.disabled = 0
          }
        })
      }
    },
    resetData () {
      this.lead = {
        phone: '',
        name: '',
        source: '',
        projects: [],
        from: ''
      }
      this.lead_id = ''
    },
    onSubmit () {
      this.loadingAddLead = true
      leadService.addLeadsWithStatus(this.lead_id ? { ...this.lead, lead_id: this.lead_id } : this.lead).then(res => {
        this.resetData()
        core.showSnackbar('success', res.data.message)
        this.$emit('refresh')
        this.$bvModal.hide('modalAddLeadWithStatus')
        this.loadingAddLead = false
      }).catch(err => {
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', `${key}: ${value}`)
        }
        this.loadingAddLead = false
      })
    },
    showDetails () {
      this.lead.name = this.listData[this.selected].data.name
      this.lead.projects = this.listData[this.selected].project_id
      this.currentUser = false
    },
    addProject (project) {
      this.lead.projects.push({ ...project, project: project.id, inquiry: '', note: '', status: '' })
    },
    removeProject (project) {
      var position = this.lead.projects.findIndex(i => i.id === project.id)
      this.lead.projects.splice(position, 1)
    }
  }
}
</script>
<style>
.periodNumbers, .projectsPrice {
  padding: 5px;
  background: transparent;
  border: 1px solid #d7dbda;
  font-size: 14px;
  color: var(--iq-secondary);
  border-radius: 10px;
  background: #92e8cb00;
}

.projectsPrice {
  background: var(--iq-bg-light-color);
  flex-wrap: wrap;
}

.list-group-item.active p, .list-group-item.active h5, .list-group-item.active small.text-muted {
  color: #fff !important;
}
</style>
